import React from "react";
import { useTranslation } from "react-i18next";

import { AppConfig } from "@config/config";
import { useWallet } from "@services/metamask/WalletProvider.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import ModalButton from "@components/dataEntry/buttons/ModalButton/ModalButton";

import "./SwitchNetwork.scss";

interface SwitchNetworkProps {
  onClose(): void;
}

const SwitchNetwork = ({ onClose }: SwitchNetworkProps) => {
  const { handleSwitchChain } = useWallet();
  const { t } = useTranslation(["modals", "common"]);

  const correctNetwork = AppConfig.ETH_NETWORK_CONFIG;
  const networkName = correctNetwork.uiName;
  const networkImage = correctNetwork.logoSrc;

  const switchChain = () => handleSwitchChain();

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={onClose}>
          <Stack alignItems="center" className="switch-network-modal">
            <img src={networkImage} alt={networkName} width={70} height="auto" className="mb-8" />
            <h3 className="mb-5">{t("modals:switchNetwork.title", { networkName })}</h3>
            <p className="p1 text-center mb-6">{t("modals:switchNetwork.description", { networkName })}</p>

            <ModalButton onClick={switchChain}>{t("common:switchNetwork")}</ModalButton>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default SwitchNetwork;
