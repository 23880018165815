import React from "react";

import "./CharacterCardSkeleton.scss";

const CharacterCardSkeleton = () => {
  return (
    <div className="character-card-skeleton">
      <div className="skeleton-animation" />
    </div>
  );
};

export default CharacterCardSkeleton;
