import React from "react";
import { useTranslation } from "react-i18next";

import { PATHS } from "@router/paths";

import HeartIcon from "@components/assets/HeartIcon";
import ChatIcon from "@components/assets/ChatIcon";
import StarIcon from "@components/assets/StarIcon";

import ToolbarNavItem from "./ToolbarNavItem/ToolbarNavItem";
import "./MobileToolbar.scss";

const MobileToolbar = () => {
  const { t } = useTranslation("layout");

  return (
    <div className="mobile-toolbar">
      <ToolbarNavItem to={PATHS.girls.path}>
        <HeartIcon />
        {t("mainSidebar.actions.grils")}
      </ToolbarNavItem>

      <ToolbarNavItem to={PATHS.chats.path} comingSoon>
        <ChatIcon />
        {t("mainSidebar.actions.chat")}
      </ToolbarNavItem>

      <ToolbarNavItem to="#" comingSoon>
        <StarIcon />
        {t("mainSidebar.actions.generate")}
      </ToolbarNavItem>
    </div>
  );
};

export default MobileToolbar;
