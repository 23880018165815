import React from "react";
import { useTranslation } from "react-i18next";

import { METAMASK_BROWSER_EXTENSION } from "@constants/constants";
import { useAppModals } from "@contexts/AppModals.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Link from "@components/navigation/Link/Link";
import ArrowUpRight from "@components/assets/ArrowUpRight";

import "./DesktopDownloadMetamask.scss";

const DesktopDownloadMetamask = () => {
  const { t } = useTranslation(["modals"]);
  const { closeModal } = useAppModals();

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack alignItems="center" className="desktop-download-metamask">
            <img src="/images/metamask-signet.svg" alt="Metamask" width={64} height={64} />
            <h3 className="text-center mt-5">{t("modals:desktopDownloadMetamask.title")}</h3>

            <p className="p1 mt-5">{t("modals:mobileUnsupportedBrowser.linkInfo")}</p>
            <Link href={METAMASK_BROWSER_EXTENSION} target="_blank" className="open-metamask-link mt-3">
              <ArrowUpRight />
              METAMASK.IO
            </Link>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default DesktopDownloadMetamask;
