const LOGO_HEIGHT = 32;

export const mediaAboutUs = [
  {
    title: "Upwork",
    image: "/images/media/upwork.svg",
    width: 105,
    height: LOGO_HEIGHT
  },
  {
    title: "Petal",
    image: "/images/media/petal.svg",
    width: 90,
    height: LOGO_HEIGHT
  },
  {
    title: "Rakuten",
    image: "/images/media/rakuten.svg",
    width: 107,
    height: LOGO_HEIGHT
  },
  {
    title: "The New York Times",
    image: "/images/media/nyt.svg",
    width: 236,
    height: LOGO_HEIGHT
  },
  {
    title: "Vice",
    image: "/images/media/vice.svg",
    width: 102,
    height: LOGO_HEIGHT
  },
  {
    title: "Dell",
    image: "/images/media/dell.svg",
    width: 100,
    height: LOGO_HEIGHT
  }
];
