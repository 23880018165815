import React, { useEffect, useReducer, useRef } from "react";

import ChevronLeft from "@components/assets/ChevronLeft";
import ChevronRight from "@components/assets/ChevronRight";

import "./ImageSlider.scss";

const CHEVRON_SIZE = 48;
const CHEVRON_COLOR = "#D097F9";
const DRAG_THRESHOLD = 100;

interface ImageSliderProps {
  id?: string;
  images: { id: string; imageUrl: string }[];
}

interface SliderState {
  currentIndex: number;
  mouseStart: number;
  dragOffset: number;
  isDragging: boolean;
}

type SliderAction =
  | { type: "SET_CURRENT_INDEX"; index: number }
  | { type: "SET_DRAG_OFFSET"; offset: number }
  | { type: "START_DRAGGING"; start: number }
  | { type: "STOP_DRAGGING" };

const initialState: SliderState = {
  currentIndex: 0,
  mouseStart: 0,
  dragOffset: 0,
  isDragging: false
};

const sliderReducer = (state: SliderState, action: SliderAction): SliderState => {
  switch (action.type) {
    case "SET_CURRENT_INDEX":
      return { ...state, currentIndex: action.index };
    case "SET_DRAG_OFFSET":
      return { ...state, dragOffset: action.offset };
    case "START_DRAGGING":
      return { ...state, isDragging: true, mouseStart: action.start };
    case "STOP_DRAGGING":
      return { ...state, isDragging: false, dragOffset: 0 };
    default:
      return state;
  }
};

const ImageSlider = ({ id = "", images }: ImageSliderProps) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [state, dispatch] = useReducer(sliderReducer, initialState);

  const goToIndex = (index: number) => {
    dispatch({ type: "SET_CURRENT_INDEX", index });
  };

  const goToNext = () => {
    goToIndex(state.currentIndex === images.length - 1 ? 0 : state.currentIndex + 1);
  };

  const goToPrevious = () => {
    goToIndex(state.currentIndex === 0 ? images.length - 1 : state.currentIndex - 1);
  };

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    if (e.preventDefault && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    const start = "touches" in e ? e.touches[0].clientX : e.clientX;
    dispatch({ type: "START_DRAGGING", start });
  };

  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!state.isDragging) return;

    const move = "touches" in e ? e.touches[0].clientX : e.clientX;
    const newDragOffset = move - state.mouseStart;
    dispatch({ type: "SET_DRAG_OFFSET", offset: newDragOffset });
  };

  const handleDragEnd = () => {
    dispatch({ type: "STOP_DRAGGING" });
    if (state.dragOffset > DRAG_THRESHOLD) goToPrevious();
    else if (state.dragOffset < -DRAG_THRESHOLD) goToNext();
  };

  const handleTouchStart = (e: React.TouchEvent) => handleDragStart(e);
  const handleTouchMove = (e: React.TouchEvent) => handleDragMove(e);
  const handleTouchEnd = () => handleDragEnd();

  const calculateTransform = () => {
    let transformValue = state.currentIndex * -100;

    if (!carouselRef.current) return;
    const offsetWidth = carouselRef.current.offsetWidth;
    transformValue += (state.dragOffset / offsetWidth) * 100;

    return `translateX(${transformValue}%)`;
  };

  const checkPaginationActive = (index: number) => (index === state.currentIndex ? "active" : "");
  const handleChooseSlide = (index: number) => () => goToIndex(index);

  const adjustPagination = () => {
    const sliderContainer = document.querySelector(".image-slider");
    if (sliderContainer instanceof HTMLElement && images.length > 0) {
      const containerWidth = sliderContainer.offsetWidth;
      const maxPaginationWidth = Math.min(containerWidth * 0.5, containerWidth);

      let itemWidth = 40; // Max item width
      let gap = 16; // Max container gap

      // We calculate the total maximum pagination width
      const maxTotalWidth = (itemWidth + gap) * images.length - gap;

      // We adjust itemWidth and gap if they exceed the maximum pagination width
      if (maxTotalWidth > maxPaginationWidth) {
        gap = 4; // Min gap
        itemWidth = (maxPaginationWidth - gap * (images.length - 1)) / images.length;
        itemWidth = Math.max(itemWidth, 4); // We keep a minimum width of 4px
      }

      const paginationContainer = sliderContainer.querySelector(".image-slider-pagination");
      if (paginationContainer instanceof HTMLElement) {
        paginationContainer.style.gap = `${gap}px`;

        const paginationItems = paginationContainer.querySelectorAll(".pagination-item");
        paginationItems.forEach((item) => {
          if (item instanceof HTMLElement) {
            item.style.width = `${itemWidth}px`;
          }
        });
      }
    }
  };

  useEffect(() => {
    adjustPagination();
    window.addEventListener("resize", adjustPagination);

    return () => {
      window.removeEventListener("resize", adjustPagination);
    };
  }, [images.length]);

  const cursorStyle = state.isDragging ? "grabbing" : "grab";

  return (
    <div
      ref={carouselRef}
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{ cursor: cursorStyle }}
      className="image-slider"
    >
      <div className="image-slider-images" style={{ transform: calculateTransform() }}>
        {images.map(({ id, imageUrl }, index) => (
          <img key={`${id || Math.random()}-slide-${index}`} src={imageUrl} alt={`Slide ${index}`} />
        ))}
      </div>

      <button className="image-slider-button left-button" onClick={goToPrevious}>
        <ChevronLeft width={CHEVRON_SIZE} height={CHEVRON_SIZE} color={CHEVRON_COLOR} />
      </button>

      <button className="image-slider-button right-button" onClick={goToNext}>
        <ChevronRight width={CHEVRON_SIZE} height={CHEVRON_SIZE} color={CHEVRON_COLOR} />
      </button>

      <div className="image-slider-pagination">
        {images.map((_, index) => (
          <span
            key={`${id}-pagination-btn-${index}`}
            className={`pagination-item ${checkPaginationActive(index)}`}
            onClick={handleChooseSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
