// DOCUMENTS ********************************************

export const LIGHTPAPER = "#";
export const PRIVACY_POLICY = "#";
export const AFFILATE = "#";

// LINKS ************************************************

export const VIDEO_EXPLAINER = "#";
export const UNISWAP = "#";
export const BUY_TOKEN = "#";

// SOCIALS **********************************************

export const INSTAGRAM = "https://www.instagram.com/";
export const TELEGRAM = "https://www.telegram.com/";
export const TWITTER = "https://twitter.com/";

// METAMASK *********************************************
export const METAMASK_APP_GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=io.metamask";
export const METAMASK_APP_APP_STORE = "https://apps.apple.com/pl/app/metamask-blockchain-wallet/id1438144202";
export const METAMASK_BROWSER_EXTENSION = "https://metamask.io/download/";
export const OPEN_METAMASK_BROWSER_URL = "https://metamask.app.link/dapp/";
