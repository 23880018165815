import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { PATHS } from "@router/paths";
import { CharacterTypes } from "@services/api/api.dtos";
import { getGirlCharacter } from "@services/api/utils/GirlPersonality";

import CharacterIcon from "../CharacterIcon/CharacterIcon";
import "./GirlCard.scss";

interface GirlCardProps {
  id: string;
  name: string;
  age: number;
  imageUrl: string;
  character?: CharacterTypes;
  className?: string;
}

const GirlCard = ({ id, name, age, imageUrl, character = "fiery", className }: GirlCardProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const navigateToGirlProfile = () => {
    if (!isDragging) navigate(PATHS.girlProfile.getPath(id));

    setIsDragging(false);
  };

  const handleMouseDown = () => setIsDragging(false);
  const handleMouseMove = () => setIsDragging(true);

  const girlCharacter = getGirlCharacter(character);
  const classes = classNames("girl-card", className);

  return (
    <div
      className={classes}
      onClick={navigateToGirlProfile}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
    >
      <img src={imageUrl} alt={name} className="girl-image" />

      <div title={t(girlCharacter?.tKey as any)} className="girl-type">
        <CharacterIcon character={character} />
      </div>

      <div className="card-content">
        <div className="girl-names">
          <span title={name} className="name">
            {name}
          </span>
          <span className="age">
            {t("age")}:{age}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GirlCard;
