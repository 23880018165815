import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { PATHS } from "@router/paths";
import { socialLinks } from "@constants/footerSocialLinks";

import SidebarNavItem from "./SidebarNavItem/SidebarNavItem";
import IconLink from "@components/navigation/IconLink/IconLink";
import HeartIcon from "@components/assets/HeartIcon";
import ChatIcon from "@components/assets/ChatIcon";
import StarIcon from "@components/assets/StarIcon";

import "./MainSidebar.scss";

interface MainSidebarProps {
  className?: string;
}

const MainSidebar = ({ className }: MainSidebarProps) => {
  const { t } = useTranslation("layout");
  const classes = classNames(`main-sidebar`, className);

  return (
    <div className={classes}>
      <div className="sidebar-actions">
        <SidebarNavItem to={PATHS.girls.path} title={t("mainSidebar.actions.grils")}>
          <HeartIcon />
          {t("mainSidebar.actions.grils")}
        </SidebarNavItem>

        <SidebarNavItem to={PATHS.chats.path} title={t("mainSidebar.actions.chat")} comingSoon>
          <ChatIcon />
          {t("mainSidebar.actions.chat")}
        </SidebarNavItem>

        <SidebarNavItem to="#" title={t("mainSidebar.actions.generate")} comingSoon>
          <StarIcon />
          {t("mainSidebar.actions.generate")}
        </SidebarNavItem>
      </div>

      <div className="social-links">
        {socialLinks.map(({ title, href, sidebarIcon }) => (
          <IconLink key={Math.random()} title={title} href={href} target="_blank">
            {sidebarIcon}
          </IconLink>
        ))}
      </div>
    </div>
  );
};

export default MainSidebar;
