import React from "react";
import classNames from "classnames";

import { scrollToIdSmooth } from "@utils/scrollUtils";
import ChevronDown from "@components/assets/ChevronDown";

import "./ScrollToButton.scss";

interface ScrollToButtonProps {
  idSelector?: string;
  sizePx?: number;
  disabled?: boolean;
  className?: string;
}

const ScrollToButton = ({ idSelector, sizePx = 84, disabled = false, className }: ScrollToButtonProps) => {
  const classes = classNames("scroll-to-button", className);

  const handleScrollTo = () => {
    if (!idSelector) return;
    scrollToIdSmooth(idSelector);
  };

  return (
    <button onClick={handleScrollTo} disabled={disabled} className={classes}>
      <ChevronDown width={sizePx} height={sizePx} />
    </button>
  );
};

export default ScrollToButton;
