import { SvgIcon } from "@src/types/SvgIcon.types";

const ChevronDown = ({ width = 84, height = 84, color = "white", opacity, className = "" }: SvgIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 84 84"
      fill="none"
      opacity={opacity}
      className={className}
    >
      <path
        d="M55.3333 37.5556L42 50.8889L28.6667 37.5556M82 42C82 19.9086 64.0914 2 42 2C19.9086 2 2 19.9086 2 42C2 64.0914 19.9086 82 42 82C64.0914 82 82 64.0914 82 42Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronDown;
