import { Path } from "./common/Path";

export const PATHS = {
  // App paths ----------------------------------------------
  main: new Path({
    path: "/"
  }),
  girls: new Path({
    path: "/girls"
  }),
  girlProfile: new Path({
    path: "/girls/:girlId",
    ref: (girlId: string): string => `/girls/${girlId}`
  }),
  chats: new Path({
    path: "/chats"
  }),
  chat: new Path({
    path: "/chats/:chatId",
    ref: (chatId: string): string => `/chats/${chatId}`
  }),
  // App paths ----------------------------------------------

  // Common paths -------------------------------------------
  debug: new Path({
    path: "/debug"
  }),
  notFound: new Path({
    path: "/*"
  })
  // Common paths -------------------------------------------
} as const;

export const mainNavPaths = [PATHS.main];
