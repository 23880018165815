import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGirlsData } from "@contexts/GirlsData.context";
import ScrollToButton from "@components/dataEntry/buttons/ScrollToButton/ScrollToButton";

import "./Hero.scss";

const Hero = () => {
  const { girlsData, topTenGirls, categorizedGirls } = useGirlsData();
  const { t } = useTranslation("mainPage");

  const downlaodGirlsError = useMemo(
    () => girlsData.error || topTenGirls.error || categorizedGirls.error,
    [girlsData, topTenGirls, categorizedGirls]
  );

  const sectionToScroll = downlaodGirlsError ? "main-page-fallback" : "main-page-top-ten-girls";

  return (
    <section id="main-page-hero" className="hero">
      <video muted loop autoPlay className="hero-video">
        <source src="/videos/hero-video.mp4" type="video/mp4" />
      </video>

      <div className="hero-content">
        <ScrollToButton idSelector={sectionToScroll} />

        <p className="p1 semi-bold mt-7 text-uppercase">{t("hero.info")}</p>
        <h1 className="hero-title mt-4">{t("hero.title")}</h1>
      </div>
    </section>
  );
};

export default Hero;
