import React, { memo } from "react";
import classNames from "classnames";

import { ButtonProps } from "./Button.types";
import "./Button.scss";
import { useTranslation } from "react-i18next";

const Button = ({
  type = "button",
  variant = "primary",
  title = "",
  disabled = false,
  loading = false,
  uppercase = true,
  fullWidth = false,
  testid = "",
  comingSoon = false,
  className = "",
  children,
  onClick = () => {},
  onBlur = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {}
}: ButtonProps) => {
  const { t } = useTranslation("common");

  const classes = classNames(
    `button button-${variant}`,
    comingSoon && `button-${variant}-coming-soon`,
    {
      "button-coming-soon": comingSoon,
      "button-loading": loading,
      uppercase: uppercase,
      "full-width": fullWidth
    },
    className
  );

  return (
    <button
      type={type}
      title={title}
      data-testid={testid}
      disabled={disabled || loading}
      className={classes}
      onClick={onClick}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}

      {comingSoon && <span className="coming-soon">{t("soon")}</span>}
    </button>
  );
};

export default memo(Button);
