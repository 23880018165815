import { WithTranslation, withTranslation } from "react-i18next";

import { mediaAboutUs } from "@constants/mediaAboutUs";

import "./MediaAboutUs.scss";

const MediaAboutUs = ({ t }: WithTranslation) => {
  return (
    <section id="main-page-media-about-us" className="media-about-us">
      <h1>{t("mediaAboutUs.title")}</h1>

      <div className="media-list">
        {mediaAboutUs.map(({ title, image, width, height }) => {
          return <img key={Math.random()} src={image} alt={title} title={title} width={width} height={height} />;
        })}
      </div>
    </section>
  );
};

export default withTranslation("mainPage")(MediaAboutUs);
