import React from "react";
import { useTranslation } from "react-i18next";

import { reloadApp } from "@utils/reloadApp";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/overlay/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import ModalButton from "@components/dataEntry/buttons/ModalButton/ModalButton";

import "./OfflineNetwork.scss";

const OfflineNetwork = () => {
  const { t } = useTranslation(["modals", "common"]);

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <Stack alignItems="center" className="offline-network">
            <img src="/images/layouts/base-error.svg" alt="offline network error" width={252} height={219} />
            <h3 className="text-center mt-8 mb-5">{t("modals:offlineNetwork.title")}</h3>
            <p className="p1 text-center mb-6">{t("modals:offlineNetwork.description")}</p>

            <ModalButton onClick={reloadApp}>{t("common:tryAgain")}</ModalButton>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default OfflineNetwork;
