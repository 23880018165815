import { girlApiClient } from "./girlApiClient.instance";
import * as ApiDtos from "./api.dtos";

export const getCurrentUser = async () => {
  const path = "/auth/user";
  const method = "GET";
  return await girlApiClient.sendAuthedRequest<any>({ url: path, method });
};

export const getGirls = async () => {
  const url = `/girls`;
  const method = "GET";

  return await girlApiClient.sendRequest<ApiDtos.GirlDto[]>({
    method,
    url
  });
};

export const getTopTenGirls = async () => {
  const url = `/girls/top-ten`;
  const method = "GET";

  return await girlApiClient.sendRequest<ApiDtos.GirlDto[]>({
    method,
    url
  });
};

export const getGirlProfile = async (id: string) => {
  const url = `/girls/${id}`;
  const method = "GET";

  return await girlApiClient.sendRequest<ApiDtos.GirlDetailsDto>({
    method,
    url
  });
};

export const getCategorizedGirls = async () => {
  const url = `/categories`;
  const method = "GET";

  return await girlApiClient.sendRequest<ApiDtos.CategorizedGirlsDto[]>({
    method,
    url
  });
};

export const getAllChats = async () => {
  const url = "/chats/all";
  const method = "GET";

  return await girlApiClient.sendAuthedRequest<ApiDtos.ChatDto[]>({
    method,
    url
  });
};

export const getChatHistory = async (chatId: string, before?: string) => {
  const url = `/chats/${chatId}/history`;
  const method = "GET";
  const params = {
    before // timestamp
  };

  return await girlApiClient.sendAuthedRequest<ApiDtos.ChatMessageDto[]>({
    url,
    method,
    params
  });
};

export const createNewChat = async (girlId: string) => {
  const url = `/chats/new/${girlId}`;
  const method = "POST";

  return await girlApiClient.sendAuthedRequest<ApiDtos.ChatDto>({
    url,
    method
  });
};

export const sendChatMessage = async (chatId: string, message: string) => {
  const url = `/chats/${chatId}`;
  const method = "POST";
  const data = {
    message
  };

  return await girlApiClient.sendAuthedRequest<ApiDtos.ChatDto>({
    url,
    method,
    data
  });
};

export const fetchSetFavoriteChat = async (chatId: string, favorite: boolean) => {
  const url = `/chats/favorite/${chatId}`;
  const method = "PUT";
  const data = {
    favorite
  };

  return await girlApiClient.sendAuthedRequest<ApiDtos.ChatDto>({
    url,
    method,
    data
  });
};

export const fetchRemoveChat = async (chatId: string) => {
  const url = `/chats/${chatId}`;
  const method = "DELETE";

  return await girlApiClient.sendAuthedRequest({
    url,
    method
  });
};
