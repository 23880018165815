import React from "react";
import { useTranslation } from "react-i18next";

import { getTKeyGirlCategory } from "@services/api/utils/GirlCategories";
import { CharacterTypes, Characters } from "@services/api/api.dtos";
import { useGirlsData } from "@contexts/GirlsData.context";

import CardCarousel from "@components/common/CardCarousel/CardCarousel";
import GirlCard from "@components/common/GirlCard/GirlCard";
import CategorizedGirlsSkeleton from "@components/skeletons/CategorizedGirlsSkeleton/CategorizedGirlsSkeleton";

import "./Categories.scss";

const Categories = () => {
  const { categorizedGirls } = useGirlsData();

  const { t } = useTranslation(["mainPage", "common"]);

  const categorizedGirlsLoading = categorizedGirls.status === "loading";

  return (
    <section id="main-page-categories-girl" className="categories">
      <h1 className="categories-title">{t("mainPage:categories.title")}</h1>

      {categorizedGirlsLoading && <CategorizedGirlsSkeleton />}

      {!categorizedGirlsLoading && (
        <div className="categories-content">
          {categorizedGirls?.data?.map(({ id, category, girls }) => {
            const categoryName = t(getTKeyGirlCategory(category) as any);

            return (
              <div key={id} className="category">
                <h4 className="category-name">{categoryName}</h4>

                <CardCarousel>
                  {girls.map(({ id, name, age, profileImageUrl, characters }) => {
                    const character = (characters[0].key as CharacterTypes) || Characters.FIERY;

                    return (
                      <GirlCard
                        key={id}
                        id={id}
                        name={name}
                        age={age}
                        character={character}
                        imageUrl={profileImageUrl}
                      />
                    );
                  })}
                </CardCarousel>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default Categories;
