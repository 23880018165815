import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { PATHS } from "@router/paths";
import { CharacterTypes } from "@services/api/api.dtos";
import { getGirlCharacter } from "@services/api/utils/GirlPersonality";

import PersonalityIcon from "../CharacterIcon/CharacterIcon";

import "./TopCard.scss";

interface TopCardProps {
  id: string;
  rank: number;
  name: string;
  category: string;
  imageUrl: string;
  character?: CharacterTypes;
  className?: string;
}

const TopCard = ({ id, rank, name, category, imageUrl, character = "fiery", className }: TopCardProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const navigateToGirlProfile = () => {
    if (!isDragging) navigate(PATHS.girlProfile.getPath(id));

    setIsDragging(false);
  };

  const handleMouseDown = () => setIsDragging(false);
  const handleMouseMove = () => setIsDragging(true);

  const girlPersonality = getGirlCharacter(character);
  const rankNumber = rank.toString().padStart(2, "0");
  const classes = classNames("top-card", className);

  return (
    <div
      className={classes}
      onClick={navigateToGirlProfile}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
    >
      <img src={imageUrl} alt={name} className="character-image" />

      <div title={t(girlPersonality?.tKey as any)} className="character-type">
        <PersonalityIcon character={character} />
      </div>

      <div className="character-details">
        <span className="character-rank">{rankNumber}</span>

        <div className="character-names">
          <span title={name} className="name">
            {name}
          </span>
          <span title={category} className="category">
            {category}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopCard;
