import React from "react";
import { useTranslation } from "react-i18next";

import { useGirlsData } from "@contexts/GirlsData.context";
import { getTKeyGirlCategory } from "@services/api/utils/GirlCategories";
import { CharacterTypes, Characters } from "@services/api/api.dtos";

import CardCarousel from "@components/common/CardCarousel/CardCarousel";
import TopCard from "@components/common/TopCard/TopCard";
import TopCardSkeleton from "@components/skeletons/TopCardSkeleton/TopCardSkeleton";

import "./TopTenGirls.scss";

const TopTenGirls = () => {
  const { topTenGirls } = useGirlsData();

  const { t } = useTranslation(["mainPage", "common"]);

  const topTenGirlLoading = topTenGirls.status === "loading";
  const mockTopGirlArray = new Array(10).fill(undefined);

  return (
    <section id="main-page-top-ten-girls" className="top-ten-girls">
      <h1 className="mb-7 title">{t("mainPage:topTenGirls.title")}</h1>

      <CardCarousel>
        {topTenGirlLoading && mockTopGirlArray.map(() => <TopCardSkeleton key={Math.random()} />)}

        {!topTenGirlLoading &&
          topTenGirls?.data?.map(({ id, name, categories, characters, profileImageUrl }, index) => {
            const category = t(getTKeyGirlCategory(categories[0].category) as any);
            const character = characters[0]?.key || Characters.FIERY;

            return (
              <TopCard
                key={id}
                id={id}
                rank={index + 1}
                name={name}
                category={category}
                character={character as CharacterTypes}
                imageUrl={profileImageUrl}
              />
            );
          })}
      </CardCarousel>
    </section>
  );
};

export default TopTenGirls;
