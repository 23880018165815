import React from "react";
import { useTranslation } from "react-i18next";

import { LIGHTPAPER, PRIVACY_POLICY, VIDEO_EXPLAINER } from "@constants/constants";

import Group from "@components/arrangement/Group/Group";
import Link from "@components/navigation/Link/Link";
import ChevronRight from "@components/assets/ChevronRight";
import ArrowUpRight from "@components/assets/ArrowUpRight";

import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation("layout");

  return (
    <footer className="footer">
      <div className="footer-row">
        <div className="row-column">
          <p className="p1 column-title">{t("footer.addToken.title")}</p>
          <img src="/images/logo-metamask.svg" alt="Metamask" width={256} height={48} />
        </div>

        <div className="row-column" style={{ gap: "14px" }}>
          <p className="p1 column-title">{t("footer.tradeToken.title")}</p>
          <img src="/images/logo-uniswap.svg" alt="Uniswap" width={230} height={58} />
        </div>

        <div className="row-column">
          <p className="p1 column-title">{t("footer.etherscan.title")}</p>
          <img src="/images/logo-etherscan.svg" alt="Uniswap" width={248} height={46} />
        </div>
      </div>

      <div className="footer-row">
        <div className="row-column">
          <p className="p1 column-title">{t("footer.lightpaper.title")}</p>

          <Group colGap={16}>
            <img src="/images/icons/icon-document.svg" alt="Download document" width={48} height={48} />
            <Link href={LIGHTPAPER}>
              {t("footer.lightpaper.download")} <ChevronRight />
            </Link>
          </Group>

          <Group colGap={16}>
            <img src="/images/icons/icon-video-player.svg" alt="Video explainer" width={48} height={48} />
            <Link href={VIDEO_EXPLAINER}>
              {t("footer.lightpaper.videoExplainer")} <ChevronRight />
            </Link>
          </Group>
        </div>

        <div className="row-column">
          <p className="p1 column-title">{t("footer.privacyPolicy.title")}</p>
          <Link href={PRIVACY_POLICY}>
            {t("footer.privacyPolicy.download")} <ArrowUpRight />
          </Link>
        </div>

        <div className="row-column">
          <p className="p1 column-title">{t("footer.affilate.title")}</p>
          <Link href="">
            {t("footer.affilate.download")} <ArrowUpRight />
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
