import React from "react";

import CardCarousel from "@components/common/CardCarousel/CardCarousel";
import CharacterCardSkeleton from "../CharacterCardSkeleton/CharacterCardSkeleton";

import "./CategorizedGirlsSkeleton.scss";

const CategorizedGirlsSkeleton = () => {
  const categoryMock = { id: Math.random(), category: "", girls: new Array(10).fill(undefined) };
  const categorizedGirlsMock = new Array(3).fill(categoryMock);

  return (
    <div className="categorized-girls-skeleton">
      {categorizedGirlsMock.map(({ girls }, categoryIndex) => {
        return (
          <div key={`category-skeleton-${categoryIndex}`} className="category-skeleton">
            <div className="category-name category-name-skeleton">
              <div className="skeleton-animation" />
            </div>

            <CardCarousel>
              {girls.map((_: any, girlIndex: any) => (
                <CharacterCardSkeleton key={`character-card-skeleton-${categoryIndex}-${girlIndex}`} />
              ))}
            </CardCarousel>
          </div>
        );
      })}
    </div>
  );
};

export default CategorizedGirlsSkeleton;
