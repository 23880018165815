export type CategoryType =
  | "nice_ladies"
  | "bad_ladies"
  | "secretary_teacher"
  | "businesswoman"
  | "science_fiction_movies";

export enum Categories {
  NICE_LADIES = "nice_ladies",
  BAD_LADIES = "bad_ladies",
  SECRETARY_TEACHER = "secretary_teacher",
  BUSINESSWOMAN = "businesswoman",
  SCIENCE_FICTION_MOVIES = "science_fiction_movies"
}

export type CharacterTypes =
  | "understanding"
  | "fiery"
  | "flirty"
  | "friendly"
  | "mysterious"
  | "intelligent"
  | "subtle"
  | "sensual"
  | "unpredictable";

export enum Characters {
  UNDERSTANDING = "understanding",
  FIERY = "fiery",
  FLIRTY = "flirty",
  FRIENDLY = "friendly",
  MYSTERIOUS = "mysterious",
  INTELLIGENT = "intelligent",
  SUBTLE = "subtle",
  SENSUAL = "sensual",
  UNPREDICTABLE = "unpredictable"
}

export enum ChatStatus {
  IDLE = "idle",
  GUEUED = "queued",
  GENERATING = "generating",
  CLOSED = "closed"
}

export interface ChatStatusDto {
  id: string;
  status: ChatStatus;
  updatedAt: string;
}

export interface AuthDto {
  token: string;
  exp: number;
}

export interface CategoryDto {
  id: string;
  category: Categories;
}

export interface ImageDto {
  id: string;
  imageUrl: string;
}

export interface GirlDto {
  id: string;
  name: string;
  characters: {
    key: string;
    value: number;
  }[];
  interests: string[];
  description: string;
  additionalInfo: string;
  age: number;
  popularity: number;
  profileImage: string;
  profileImageUrl: string;
  categories: CategoryDto[];
}

export interface CategorizedGirlsDto {
  id: string;
  category: string;
  girls: GirlDto[];
}

export interface GirlDetailsDto {
  id: string;
  name: string;
  characters: {
    key: string;
    value: number;
  }[];
  interests: string[];
  description: string;
  additionalInfo: string;
  age: number;
  popularity: number;
  profileImage: string;
  profileImageUrl: string;
  categories: CategoryDto[];
  images: ImageDto[];
  blurredPhotos: ImageDto[];
}

export interface ChatDto {
  id: string;
  updatedAt: string;
  createdAt: string;
  status: ChatStatus;
  reported: boolean;
  favorite: boolean;
  girl: {
    id: string;
    name: string;
    profileImageUrl: string;
  };
  history: ChatMessageDto[];
  latest: ChatMessageDto;
}

export interface ChatMessageDto {
  id: string;
  createdAt: string;
  updatedAt: string;
  content: string;
  aiSide: boolean;
}

export interface ChatMessageReceivedDto {
  id: string;
  chat: string; // chat id
  createdAt: string;
  updatedAt: string;
  content: string;
  aiSide: boolean;
}
