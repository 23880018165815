import React from "react";
import { useTranslation } from "react-i18next";

import { socialLinks } from "@constants/footerSocialLinks";
import { BUY_TOKEN, UNISWAP } from "@constants/constants";

import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import IconLink from "@components/navigation/IconLink/IconLink";
import CloseIcon from "@components/assets/CloseIcon";

import "./MobileMenu.scss";

interface MobileMenuProps {
  onClose?(): void;
}

const MobileMenu = ({ onClose = () => {} }: MobileMenuProps) => {
  const { t } = useTranslation(["common", "layout"]);

  return (
    <div className="mobile-menu">
      <div className="mobile-menu-box">
        <button onClick={onClose} className="close-btn">
          <CloseIcon />
          {t("common:close")}
        </button>

        <div className="mobile-menu-content">
          <Stack rowGap={40} className="menu-actions">
            <a href={BUY_TOKEN} target="_blank" rel="noopener noreferrer nofollow" className="mobile-menu-action">
              {t("layout:navbar.links.buyToken")}
            </a>

            <a href={UNISWAP} target="_blank" rel="noopener noreferrer nofollow" className="mobile-menu-action">
              <img src="/images/uniswap-signet.svg" alt="Uniswap signet" height={48} />
              {t("layout:navbar.links.uniswap")}
            </a>
          </Stack>

          <Group colGap={32} className="social-links">
            {socialLinks.map(({ title, href, icon }) => (
              <IconLink key={Math.random()} title={title} href={href} target="_blank">
                {icon}
              </IconLink>
            ))}
          </Group>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
