import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { BUY_TOKEN, UNISWAP } from "@constants/constants";
import { scrollToTop } from "@utils/scrollUtils";

import useBlockScroll from "@hooks/useBlockScroll";

import Group from "@components/arrangement/Group/Group";
import Link from "@components/navigation/Link/Link";

import ShoppingCart from "@components/assets/ShoppingCart";
import HamburgerIcon from "@components/assets/HamburgerIcon";

import ConnectMetamask from "../ConnectMetamask/ConnectMetamask";
import MobileMenu from "../MobileMenu/MobileMenu";
import "./Navbar.scss";
import UniswapSignet from "@components/assets/UniswapSignet";

const Navbar = () => {
  const [menuOpen, setOpenMenu] = useState<boolean>(false);
  const { handleLocked, handleUnlocked } = useBlockScroll();

  const { t } = useTranslation("layout");

  const handleOpenMenu = () => {
    setOpenMenu(true);
    handleLocked();
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
    handleUnlocked();
  };

  return (
    <>
      <div className="navbar">
        <button onClick={scrollToTop}>
          <img src="/images/logo-girl-ai.png" alt="Girls AI" width={68} height={24} />
        </button>

        <Group className="navbar-actions">
          <Group className="navbar-actions-links">
            <Link variant="secondary" href={UNISWAP} className="uniswap-link">
              <UniswapSignet width={20} height={20} className="uniswap-link-icon" />
              {t("navbar.links.uniswap")}
            </Link>

            <Link variant="secondary" href={BUY_TOKEN}>
              <ShoppingCart /> {t("navbar.links.buyToken")}
            </Link>
          </Group>

          <Group colGap={8} className="navbar-actions-buttons">
            <ConnectMetamask onClick={handleCloseMenu} />

            <button onClick={handleOpenMenu} className="hamburger-btn">
              <HamburgerIcon />
            </button>
          </Group>
        </Group>
      </div>

      {menuOpen && <MobileMenu onClose={handleCloseMenu} />}
    </>
  );
};

export default Navbar;
